<template>
    <div class="container-xxl">
        <h6><router-link :to="{name:'Info_create'}">公告管理</router-link>&ensp;>&ensp;公告明細&ensp;
            <b-icon v-show="obj.typ=='alert'" icon="bell-fill" variant="warning" font-scale="1.5" ></b-icon>
            <b-icon v-show="obj.typ=='mess'" icon="chat-quote-fill" variant="success" font-scale="1.5"></b-icon>
        </h6>
      <b-card :header="obj.title">
      <blockquote class="blockquote mb-0">
       <div style="text-align:left;font-size: 16px;">刊登日期 : {{fdateformat(obj.created_at)}} | 發文者 : {{obj.fname}}</div>
        <p></p>
        <div style="text-align:left;font-size: 16px;"><span class="zoomImage" v-html="obj.content" /></div>       
      </blockquote>
    </b-card>
    </div>
</template>
<script>
import { messService } from '../../../_services/'
import * as moment from "moment/moment";

const queryData = params => {
  return messService.finditem(params.page) 
};

export default{
    name: 'Record_detail',     
    data(){
        return{
            uId:{String},
            obj:[],
        }
    },
  methods: {
    fetch(params = {}) {
      queryData({
        ...params,
      }).then(({ data }) => {
          this.obj = data;
      });
    },
    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    }
  },
    mounted(){
        //console.log(this.$route.query.userId);
        this.uId = this.$route.params.id
        this.fetch({page:this.uId});
    },
    

}
</script>
<style scope>
img {  
    max-height: 100%;  
    max-width: 100%; 
    width: auto;
    height: auto;
    top: 0;  
    bottom: 0;  
    left: 0;  
    right: 0;  
    margin: auto;
}
</style>